import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const elombe = () => (
  <Layout>
    <SEO title="Sentier et Chemin - Elombé" />
    <h3 className='underline-title'>Elombé (1)</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Une lance dans une main<br />
        Un <span className="font-italic">"pamba"</span> (2) dans l'autre<br />
        Crâne<br />
        Frappant en silence<br />
        <span className="par-starting"></span>sans slogan<br />
        Détruisant avec sourire<br />
        <span className="par-starting"></span>sans haine<br />
        Malmenant avec douceur<br />
        <span className="par-starting"></span>sans hargne<br />
      </p>
      <p>
        Il franchit la barrière<br />
        <span className="par-starting"></span>tête haute<br />
        <span className="par-starting"></span>torse bombé<br />
        <span className="par-starting"></span>aguerri<br />
      </p>
      <p>
        Il part <br />
        <span className="par-starting"></span>sans remords<br />
        <span className="par-starting"></span>sans souci<br />
        <span className="par-starting"></span>sans blessure<br />
        Il part sans espoir de retour<br />
        Elombé! Elombééé!!!<br />
      </p>
      <p>
        Avec vaillance il a combattu<br />
        Poitrine crânement bombée à la prussienne<br />
        Il ne dira point:<br />
        <span className="par-starting"></span>"Galiléen tu m'as vaincu"<br />
        Son ennemi n'est jamais Galiléen<br />
        C'est plutôt un Philistin!<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">
      (1) Héros<br />
      (2) Grand couteau<br />
    </p>
    <br />
    <br />
    <Link to="/sentier-et-chemin-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default elombe
